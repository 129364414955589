import './Write.css'
import {useNavigate} from "react-router-dom";

export default function Write(){
    const navigate = useNavigate();
    return (
        <>
            <div className="write-background"></div>
            <div className="write-back" onClick={()=>{navigate('/');}}>&lt;&lt; înapoi</div>
            <div className="write-caret" onClick={()=>{window.open("https://youtu.be/KJ70_WFJjG0?feature=shared");}}>^</div>
            <div className="write-placeholder">
                la pagina asta <br/>
                încă se lucrează <br/>
                la fel ca la<br/>
                multe alte pagini <br/>
                la care <br/>
                încă se lucrează. <br/>
            </div>
        </>
    )
}